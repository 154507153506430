@use '../../../../../variables.module.scss' as v;

.link {
  text-decoration: underline;
}

.licenseWrapper {
  padding: 0 10%;
  
  .copyrightText {
    font-size: v.$font-12;

    .networkLink {
      @extend .link;

      color: black;
    }    
  }

  .licenseLink {
    @extend .link;

    color: v.$primary-orange-05;
  }
}