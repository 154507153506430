.calculatorWrapper {
  // height: 760px;

  display: flex;
  flex-direction: column;
}

// For mobile Devices
// @media only screen and (max-width: 750px) {
//   .calculatorWrapper {
//     height: auto;
//   }
// }
