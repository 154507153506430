@use './variables.module.scss' as v;

@import '../node_modules/@appkit4/styles/appkit.min.css';
@import '../node_modules/@appkit4/react-components/dist/styles/appkit4-react.min.css';

// Stepper Override
.ap-progress-stepper-container {
  width: fit-content !important;

  .ap-progress-stepper {
    width: 6rem !important;
  }
  
  .ap-progress-stepper-btn {
    pointer-events: none !important;
  }
  
  .bar-active {
    background-color: v.$primary-orange-05 !important;
  }
  
  .icon-circle-radio-outline {
    color: v.$primary-orange-05 !important;
  }

  .icon-circle-checkmark-outline {
    color: v.$primary-orange-05 !important;
  }
}

// // Radio override
.ap-radio-container {
  .icon-radio-selected-fill {
    color: v.$primary-orange-05 !important;
  }
}


.calculator-input {
  .ap-field-wrapper {
    height: 2rem !important;

    input {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.calculator-input-cursor-right {
  input {
    text-align: right !important;
  }
}

.calculator-list-hover {
  &:hover {
    background-color: v.$neutral-02;
  }
}

.inputError {
  color: v.$primary-red-05;
  font-size: v.$font-12;
}

@media print {
  .no-print {
    visibility: hidden;
  }
}

@media only screen and (max-width: 750px) {
  .ap-progress-stepper-container {
    .ap-progress-stepper {
      width: 4rem !important;
    }
  }
}