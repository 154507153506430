$neutral-02: #F3F3F3;
$neutral-06: #C5C5C5;
$neutral-19: #303030;

$primary-orange-05: #D04A02;

$primary-red-05: #E0301E;

$primary-blue: #295477; // Cannot find in Appkit color kit

$primary-pink-05: #D93954;

$dv-orange: #EB8C00;
$dv-yellow: #FFB600;

$boarder-divider: #F3F3F3;

$font-12: .75rem;
$font-14: .875rem;
$font-16: 1rem;
$font-20: 1.25rem;
$font-24: 1.5rem;
