@use '../../../../../../../variables.module.scss' as v;

.stepHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-bottom: 1rem;

  .titleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    .title {
      font-size: v.$font-24;
      line-height: 2rem;
      font-weight: 500;
    }
  }

  .infoButton {
    margin-top: 0.25rem;
    font-size: v.$font-24 !important;
    color: v.$neutral-06;
    cursor: pointer;
  }

  .infoOpened {
    color: v.$neutral-19 !important;
  }

  .infoText {
    white-space: pre-wrap !important;
    padding: 0 1rem;
    font-size: v.$font-14;
  }
}

@media only screen and (max-width: 750px) {
  .stepHeader {
    .titleWrapper {
      .title {
        font-size: v.$font-16;
      }
    }

    .infoButton {
      margin-top: 0;
      font-size: v.$font-20 !important;
    }    
  }
}
