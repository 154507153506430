@use '../../../../../../variables.module.scss' as v;


.boxPadding {
  padding: 1rem 2rem;
}

.sumText {
  color: #ffffff;
}

.calculationResult {
  width: 100%;

  .calculationResultWrapper {
    padding: 0 10%;

    .sumBox {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      @extend .boxPadding;

      background-color: v.$primary-orange-05;

      .sumLabel {
        @extend .sumText;
        font-size: v.$font-16;
      }

      .sumValue {
        @extend .sumText;
        font-size: v.$font-24;
        font-style: italic;
      }
    }

    .sumDetail {
      @extend .boxPadding;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .calculationResult {
    .calculationResultWrapper {
      padding: 0 5%;

      .sumDetail {
        padding: 2%;
        font-size: v.$font-14;
      }
    }
  }
}
