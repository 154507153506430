@use '../../../../../../variables.module.scss' as v;

.guaranteeCommision {
  .guaranteeCommisionInputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .guaranteeCommisionList {
      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 0;
      
      .guaranteeCommisionRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 0.25rem 0;

        border-bottom: 0.5px solid v.$neutral-02;

        .radioColumn {
          width: 5%;
        }

        .landColumn {
          white-space: pre-line;
          width: 45%;
        }

        .provisionColumn {
          width: 20%;
        }

        .calculationBaseColumn {
          width: 30%;
        }
      }    
    }
  }

  .additionalInfoWrapper {
    padding: 1rem;

    .infoText {
      font-size: v.$font-14;
    }
  }  
}

// For mobile Devices
@media only screen and (max-width: 750px) {  
  .guaranteeCommision {
    .guaranteeCommisionInputWrapper {
      font-size: v.$font-12;

      .guaranteeCommisionRow {
        .calculationBaseColumn {
          min-width: 30%;

          label {
            font-size: v.$font-12 !important;
          }
        }

        .provisionColumn {
          word-wrap: break-word;
        }
      }
    }
  }
}