@use '../../../../../../variables.module.scss' as v;

.recoveryRate {
  .inputLabel {
    text-align: center;
  }
  .recoveryRateInputWrapper {
    display: flex;
    justify-content: center;

    padding-top: 1rem;


    .recoveryRateGroup {
      display: flex;
      gap: 1rem;

      .inputLabel {
        font-size: v.$font-20;
      }
    }
  }
}
