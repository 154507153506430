@use '../../../../variables.module.scss' as v;

.calculatorLoaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: center;

  .loadingMessage {
    padding: .5rem;
    font-weight: bold;
    font-size: v.$font-20;
  }
}