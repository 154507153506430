@use '../../../../variables.module.scss' as v;

.footerWrapper {
  display: flex;
  flex-direction: column;

  .buttonWrapper {
    display: flex;
    gap: .5rem;
    justify-content: center;
  
    padding: .5rem 0;
    margin-top: .25rem;
  }

  .copyrightWrapper {
    margin-top: 1rem;
  }
}

.printButton {
  background-color: v.$primary-orange-05 !important;
}

.nextButton {
  background-color: v.$dv-orange !important;
}

.backButton {
  background-color: v.$dv-yellow !important;
}

.resetButton {
  background-color: v.$primary-pink-05 !important;
}

@media only screen and (max-width: 750px) {
  .footerWrapper {
    .footerButton {
      padding: .5rem .75rem;
      font-size: v.$font-14;
    }
  }
}