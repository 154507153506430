@use '../../../../../../variables.module.scss' as v;

.creditHistoryWrapper {
    width: 100%;

  .creditHistoryInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .creditHistoryList {
      display: flex;
      flex-direction: column;

      padding: 0;      
      // max-height: 22rem;
      // overflow-y: auto;

      .creditHistoryRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        padding: 0.5rem 0.5rem;
        border-bottom: 0.5px solid v.$neutral-02;

        .inputColumn {
          display: flex;
          align-items: center;
          justify-content: center;          
        }

        .yearColumn {       
          @extend .inputColumn;
          width: 10%;
        }

        .creditStandingColumn {
          @extend .inputColumn;
          width: 45%;
        }

        .guaranteeQuotaColumn {          
          @extend .inputColumn;          
          width: 45%;
        }
      }
    }

    .highlightText {
      font-size: v.$font-12;
      font-weight: bold;
    }

    .inputGrow {
      flex-grow: 1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .creditHistoryWrapper {
    .creditHistoryInputWrapper {
      .creditHistoryList {
        max-height: none;
        font-size: v.$font-12;
      }
    }
  }
}
