.stepWrapper {
  display: flex;
  justify-content: center;

  min-height: 580px;
  padding: 0 10%;
}

// For mobile Devices
@media only screen and (max-width: 750px) {
  .stepWrapper {
    padding: 0 2%;
  }
}
