@use '../../../../../../variables.module.scss' as v;

.guranteeRating {
  width: 100%;

  .guranteeRatingInputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .guaranteeRatingList {
      display: flex;
      flex-direction: column;

      text-align: center;
      padding: 0;
      
      .guaranteeRatingRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
        padding: 0.5rem 0;

        border-bottom: 0.5px solid v.$neutral-02;

        .radioColumn {
          display: flex;
          justify-content: center;
          width: 20%;
        }

        .rateColumn {
          width: 40%;
        }

        .classColumn {
          width: 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .guranteeRating {
    .guranteeRatingInputWrapper {
      .dateHint {
        font-size: v.$font-14;
      }

      .guaranteeRatingList {
        font-size: v.$font-12;
      }
    }
  }
}